button.mr-2.navbar-toggle{
  font-size: 30%;
}

.navbar{
  background-color: #3E5962;
}
.formy{
  margin-bottom: 10%;
}
#align{
  text-align: center;
  margin-right: 35%;
}
.Trends{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-template-rows: auto auto;
  overflow: scroll;
  gap: 10px;
}
.Trends img{
  width: 120px;
  height: 179px;
}
.Trends::-webkit-scrollbar {
  display: none;
}

.Genres{
  color:#FF00FF;
  background-color: #212D31;
}
.Genres::-webkit-scrollbar {
  display: none;
}
.icon{
  width: 59px;
}
.icon path{
  width: 59px;

}

button.author{
    display: flex;
    width: 120px;
    height: 20px;
    font-size: 11px;
  
}
.body{
  background-image: 'https://www.stylist.co.uk/images/app/uploads/2019/12/17162852/the-end-of-a-book-1268x845.jpeg?w=1200&h=1&fit=max&auto=format%2Ccompress';
}
.App{
  background-image: url('https://www.stylist.co.uk/images/app/uploads/2019/12/17162852/the-end-of-a-book-1268x845.jpeg?w=1200&h=1&fit=max&auto=format%2Ccompress');
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
.main{
  display: grid;
  grid-template-rows: auto auto auto auto auto;
  gap: 2em;
  
 }
.Upcoming{
  display: grid;
  grid-template-columns: 9.3em 9.3em;
  grid-template-rows: 11.2em 11.2em;
  margin: 0px 15% 10% 15%;
  gap: 20px
   
}
.text{
  color: #FF00FF;
  text-align: center;
  margin: 2em 0 0 0;
  background-color: #212D31;
}
.Kids{
  display: grid;
  grid-template-columns: 9.3em 9.3em;
  grid-template-rows: 11.2em 11.2em;
  margin: 0px 15%;
  margin-bottom: 10%;
  gap: 20px
   
}
#Kids, #Trending, #Upcoming{
  border: none;
  padding: 0;
  height: 179px;
  width: 120px;
}


/* Carousel */
/* .title{
  color:#FF00FF;
  background-color: #212D31;
} */
div.carousel.slide a.carousel-control-prev{
  display: none !important
}

div.carousel.slide a.carousel-control-next{
  display: none !important
}

.boo .carousel-item img.w-100{
  width: 15em !important;
  height: 20em;
  margin-left: 5.8em;
}


.books{
  display: flex;
  flex-direction: column;
}
.book-image{
  width: 100%;
   height: 100%; 
  margin:auto;
}
.modal-title{
  display: flex;
  flex-direction: column;
  }
  
  #book-modal{
    background-color: #222D31;
    padding: 0;
    width: 70%;
   height: 70%; 
  margin: 20px 4em;
  }

.form-inline{
  margin-bottom: 10%;
}
#newsletter{
  text-align: center;
  border-top: solid 10px black;
}

.copyright{
  text-align: center;
}
/* medi for tablet */
@media only screen and (min-width: 768px){ 
  .boo .carousel-item img.w-100{
    width: 25em !important;
    height: 35em;
    margin: auto;
  }
 
  .Kids {
    margin-left: 30%;
    margin-bottom: 5%;
  }
  #Kids, #Trending, #Upcoming{
    border: none;
    padding: 0;
  }
  .Upcoming{
    margin-left: 30%;
  }

  
  .book-image{
    width: 100%;
     height: 100%; 
    margin:auto;
  }
  .modal-title{
    display: flex;
    flex-direction: column;
    }
    
    #book-modal{
      background-color: #222D31;
      padding: 0;
      width: 18em;
    height: 25em; 
    margin: 20px 16em;
    border: none;
    }
  .navbar#navy{
    display: flex;
    flex-direction: column;

  }
  #align{
    margin: 0;
  }
  .form-inline{
    margin-bottom: 2%;
  }
  
}
/* media for the laptop/desktop */
@media only screen and (min-width: 1024px){
  .boo .carousel-item img.w-100{
    width: 10em !important;
    height: 15em;
    margin: auto;
  }
  .Trends{
    margin-left: 4%;
  }
  .Kids {
    margin-left: 35%;
  }
  #Kids, #Trending, #Upcoming{
    border: none;
    padding: 0;
  }
  .Upcoming{
    margin-left: 35%;
  }
 
  #book-modal{
    background-color: #222D31;
    padding: 0;
    width: 30em;
    height: 40em; 
  margin: 20px 19em;
  border: none;
  }
  .navbar#navy{
    display: flex;
    flex-direction: column;

  }
  #align{
    margin: 0;
  }
  .Kids{
    margin-bottom: 10%;
  }
  .form-inline{
    margin-bottom: 2%;
  }

}